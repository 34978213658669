import React from 'react';
import {
  alphabet,
  AlphabetType,
  defaultWidth,
  fillColor,
  svgDefs,
  viewBox,
} from './alphabetConstants';
import useId from '../../../hooks/useId';

const getFirstLetter = (text: string): string | undefined => {
  if (text && text.length > 0) {
    return text.charAt(0).toLowerCase();
  }
  return undefined;
  // throw new Error("can't find first letter svg for text=" + text);
};

export type LetterSvgProps = {
  width?: number;
  stopColor1?: string;
  stopColor2?: string;
};

const defaultColor1 = '#45EA00';
const defaultColor2 = '#5472FF';

export const FirstLetterSvg = ({
  forText,
  stopColor1 = defaultColor1,
  stopColor2 = defaultColor2,
  ...letterSvgProps
}: {
  forText: string;
  stopColor1?: string;
  stopColor2?: string;
} & LetterSvgProps) => {
  const firstLetter = getFirstLetter(forText);
  if (!firstLetter) return null;
  const letterConfig: AlphabetType | undefined = alphabet.find(
    each => each.symbol === firstLetter,
  );
  if (!letterConfig) return null;

  return (
    <SvgWrapper
      letter={firstLetter}
      letterConfig={letterConfig}
      stopColor1={stopColor1}
      stopColor2={stopColor2}
      letterProps={letterSvgProps}
    />
  );
};

const SvgWrapper = ({
  letter,
  letterConfig,
  stopColor1,
  stopColor2,
  letterProps = {},
}: {
  letter: string;
  letterConfig: AlphabetType;
  stopColor1: string;
  stopColor2: string;
  letterProps?: LetterSvgProps;
}) => {
  const id = useId();

  return (
    <svg
      className={`letter letter-${letter}`}
      width={defaultWidth}
      viewBox={viewBox}
      {...letterProps}
    >
      {svgDefs(id, stopColor1, stopColor2)}
      {!letterConfig.basePath && (
        <path
          fill={fillColor}
          d="M350,235.68A114.45,114.45,0,0,0,235.68,350h0A114.32,114.32,0,1,0,350,235.68Zm0,178.64A64.39,64.39,0,0,1,285.68,350h0A64.32,64.32,0,1,1,350,414.32Z"
        />
      )}
      {letterConfig.basePath && letterConfig.basePath.length > 0 && (
        <>
          {letterConfig.basePath.map((each: string, index: number) => (
            <path key={id + '' + index} fill={fillColor} d={each} />
          ))}
        </>
      )}
      {letterConfig.paths &&
        letterConfig.paths.map((each: string, index: number) => (
          <path
            key={id + '' + index}
            fill={`url(#letter-gradient-${id})`}
            d={each}
          />
        ))}
      {letterConfig.circle && (
        <circle
          fill={`url(#letter-gradient-${id})`}
          cx={letterConfig.circle.cx}
          cy={letterConfig.circle.cy}
          r={letterConfig.circle.r}
        />
      )}
      {letterConfig.rect && (
        <rect
          fill={`url(#letter-gradient-${id})`}
          x={letterConfig.rect.x}
          y={letterConfig.rect.y}
          width={letterConfig.rect.width}
          height={letterConfig.rect.height}
        />
      )}
    </svg>
  );
};

export default FirstLetterSvg;
