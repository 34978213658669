import React from 'react';

export const defaultWidth = 250;
export const fillColor = '#EDEDED';
export const viewBox = '116 116 466 466';
export const svgDefs = (
  id: string | undefined,
  stopColor1: string = 'black',
  stopColor2: string = 'red',
) => (
  <defs>
    <linearGradient
      id={`letter-gradient-${id}`}
      x1="0%"
      y1="50%"
      x2="100%"
      y2="50%"
    >
      <stop
        offset={0}
        style={{
          stopColor: stopColor1,
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: stopColor2,
        }}
      />
    </linearGradient>
  </defs>
);
export type AlphabetType = {
  symbol: string;
  basePath?: string[];
  paths?: string[];
  rect?: {
    x: string;
    y: string;
    width: string;
    height: string;
  };
  circle?: {
    cx: string;
    cy: string;
    r: string;
  };
};

export const alphabet: AlphabetType[] = [
  {
    symbol: 'a',
    paths: [
      'M464.65,350h0A114.45,114.45,0,0,0,350.32,235.68h0A114.45,114.45,0,0,0,236,350h50a64.39,64.39,0,0,1,64.32-64.32h0a64.32,64.32,0,0,1,0,128.64v50a114.16,114.16,0,0,0,89.32-43.06A114.16,114.16,0,0,0,529,464.32v-50A64.39,64.39,0,0,1,464.65,350Z',
    ],
  },
  {
    symbol: 'b',
    paths: [
      'M350,235.68v50a64.32,64.32,0,0,1,0,128.64h0A64.39,64.39,0,0,1,285.68,350V178h-50V350h0A114.45,114.45,0,0,0,350,464.32h0a114.32,114.32,0,0,0,0-228.64Z',
    ],
  },
  {
    symbol: 'c',
    paths: [
      'M304.52,395.48h0a64.39,64.39,0,0,1,0-91h0a64.39,64.39,0,0,1,91,0l35.36-35.36a114.45,114.45,0,0,0-161.68,0h0a114.45,114.45,0,0,0,0,161.67h0a114.45,114.45,0,0,0,161.68,0l-35.36-35.36A64.39,64.39,0,0,1,304.52,395.48Z',
    ],
  },
  {
    symbol: 'd',
    paths: [
      'M464.45,350.15h-.08v0h0V178.92h-50V350.09a64.39,64.39,0,0,1-64.32,64.32h0a64.32,64.32,0,0,1,0-128.64v-50a114.32,114.32,0,0,0,0,228.64h0a114.16,114.16,0,0,0,89.33-43.07,114.16,114.16,0,0,0,89.38,43.13v-50A64.39,64.39,0,0,1,464.45,350.15Z',
    ],
  },
  {
    symbol: 'e',
    paths: [
      'M464.25,350.06a114.32,114.32,0,0,0-228.64,0h0a114,114,0,0,0,33.52,80.79l0,0a113.7,113.7,0,0,0,36.09,24.36l.85.36,1.31.54,2.07.81.1,0a113.75,113.75,0,0,0,40.41,7.38v-.05a114,114,0,0,0,80.84-33.43l-35.36-35.36a64.39,64.39,0,0,1-66.3,15.36l-.68-.23c-.6-.21-1.2-.45-1.8-.68l-1.35-.53-1.1-.48c-.66-.29-1.33-.58-2-.9l-.42-.21A64.31,64.31,0,1,1,408,322.45l-77.85.68v50H461.91a114.39,114.39,0,0,0,2.34-23.08',
    ],
  },
  {
    symbol: 'f',
    paths: [
      'M350.28,181V131A114.45,114.45,0,0,0,236,245.33v97.22c-.18,2.63-.28,5.29-.28,8H236V464.84h50V344.62a64.4,64.4,0,0,1,64-58.42v-50a113.67,113.67,0,0,0-64,19.67V245.33A64.39,64.39,0,0,1,350.28,181Z',
    ],
  },
  {
    symbol: 'g',
    paths: [
      'M464.32,350A114.45,114.45,0,0,0,350,235.68h0A114.45,114.45,0,0,0,235.68,350h0A114.45,114.45,0,0,0,350,464.32v-50A64.39,64.39,0,0,1,285.68,350h0a64.32,64.32,0,1,1,128.64,0h0V458.63h0A64.39,64.39,0,0,1,350,522.95v50A114.45,114.45,0,0,0,464.31,459.59h0V350Z',
    ],
  },
  {
    symbol: 'h',
    paths: [
      'M464.44,349.52A114.45,114.45,0,0,0,350.13,235.8h0a113.67,113.67,0,0,0-64.32,19.86V178h-50V464.34h50V350.12a64.39,64.39,0,0,1,64.32-64.32h0a64.39,64.39,0,0,1,64.32,64.32V464.44h50V349.52Z',
    ],
  },
  {
    symbol: 'i',
    paths: [
      'M286.21,350.15V255.73h-50v94.44h0a114.45,114.45,0,0,0,114.32,114.3v-50A64.39,64.39,0,0,1,286.21,350.15Z',
    ],
    circle: {
      cx: '261.65',
      cy: '215.44',
      r: '27.75',
    },
  },
  {
    symbol: 'j',
    paths: [
      'M414.22,458.24a64.39,64.39,0,0,1-64.32,64.32v50A114.45,114.45,0,0,0,464.22,458.24v-203h-50Z',
    ],
    circle: {
      cx: '439.22',
      cy: '213.94',
      r: '27.75',
    },
  },
  {
    symbol: 'k',
    paths: [
      'M420.33,261.14a114.16,114.16,0,0,0,43.05-89.32h-50a64.39,64.39,0,0,1-64.23,64.32h-.09v50h.09a64.39,64.39,0,0,1,64.23,64.32V464.78h50V349.83h0A114.16,114.16,0,0,0,420.33,261.14Z',
    ],
    rect: {
      x: '235.88',
      y: '171.82',
      width: '50',
      height: '292.96',
    },
  },
  {
    symbol: 'l',
    paths: [
      'M285.68,350.12V172h-50V351.12h0A114.45,114.45,0,0,0,350,464.45v-50A64.39,64.39,0,0,1,285.68,350.12Z',
    ],
  },
  {
    symbol: 'm',
    basePath: [
      'M504.07,350a64.39,64.39,0,0,1-64.32,64.32h0A64.39,64.39,0,0,1,375.43,350H325.34A64.39,64.39,0,0,1,261,414.32h0A64.39,64.39,0,0,1,196.79,350h-50A114.45,114.45,0,0,0,261.11,464.32h0a114.16,114.16,0,0,0,89.28-43.11,114.16,114.16,0,0,0,89.36,43.11h0A114.45,114.45,0,0,0,554.07,350Z',
    ],
    paths: [
      'M439.75,235.68h0a114.16,114.16,0,0,0-89.34,43.08,114.16,114.16,0,0,0-89.31-43v0A114.45,114.45,0,0,0,146.79,349.75h0V464.17h50V349.87a64.39,64.39,0,0,1,64.32-64.2v0A64.39,64.39,0,0,1,325.43,350h0V464.3h50V350a64.39,64.39,0,0,1,64.32-64.32h0A64.39,64.39,0,0,1,504.07,350V464.31h50V350h0A114.45,114.45,0,0,0,439.75,235.68Z',
      'M146.79,350c0-.08,0-.17,0-.25h0Z',
    ],
  },
  {
    symbol: 'n',
    paths: [
      'M350.09,235.68h0A114.45,114.45,0,0,0,235.77,350h0V464.32h50V350h0a64.39,64.39,0,0,1,64.32-64.32h0A64.39,64.39,0,0,1,414.41,350V464.32h50V350A114.45,114.45,0,0,0,350.09,235.68Z',
    ],
  },
  {
    symbol: 'o',
    paths: [
      'M350,235.68A114.45,114.45,0,0,0,235.68,350h0A114.32,114.32,0,1,0,350,235.68Zm0,178.64A64.39,64.39,0,0,1,285.68,350h0A64.32,64.32,0,1,1,350,414.32Z',
    ],
  },
  {
    symbol: 'p',
    paths: [
      'M350,236.18a114.45,114.45,0,0,0-114.32,114.3h0V571.34h50V350.5A64.32,64.32,0,1,1,350,414.82v50a114.32,114.32,0,0,0,0-228.64Z',
    ],
  },
  {
    symbol: 'q',
    paths: [
      'M350,286.18a64.39,64.39,0,0,1,64.32,64.32h50A114.45,114.45,0,0,0,350,236.18h0A114.45,114.45,0,0,0,235.68,350.5h50A64.39,64.39,0,0,1,350,286.18Z',
      'M399,350.5H349A114.45,114.45,0,0,0,463.32,464.82v-50A64.39,64.39,0,0,1,399,350.5Z',
    ],
  },
  {
    symbol: 'r',
    paths: [
      'M430.84,269.11A114,114,0,0,0,350,235.68h0l-2.35,0h-.41l-2,.07-.84,0-1.9.11-.9.06-1.84.15-1,.09-1.75.19-1.08.12-1.59.21-1.27.18-1.34.21-1.56.26-1,.18-2,.38-.58.12-2.47.54h0a113.63,113.63,0,0,0-55,30.5l.06.06A114,114,0,0,0,235.68,350V464.32h50V350h0A64.39,64.39,0,0,1,350,285.68v0a64.12,64.12,0,0,1,45.48,18.81Z',
    ],
  },
  {
    symbol: 's',
    paths: [
      'M414.49,350h0a64.39,64.39,0,0,1-64.32,64.3v50A114.45,114.45,0,0,0,464.49,350h0a64.39,64.39,0,0,1,64.32-64.3v-50A114.45,114.45,0,0,0,414.49,350Z',
    ],
  },
  {
    symbol: 't',
    paths: [
      'M285.8,350.13V285.8h64.32v-50H285.8V177h-50V350.13A114.45,114.45,0,0,0,350.12,464.45v-50A64.39,64.39,0,0,1,285.8,350.13Z',
    ],
  },
  {
    symbol: 'u',
    paths: [
      'M414.32,235.83v114.3A64.39,64.39,0,0,1,350,414.45h0a64.39,64.39,0,0,1-64.32-64.32v-38.4h-50v38.4A114.45,114.45,0,0,0,350,464.45h0A114.45,114.45,0,0,0,464.32,350.14h0V235.83Z',
    ],
  },
  {
    symbol: 'v',
    paths: [
      'M285.68,235.82v114.3A64.39,64.39,0,0,0,350,414.45h0a64.39,64.39,0,0,0,64.32-64.32v-38.4h0l.08-76h100v50h-50l-.08,64.4A114.45,114.45,0,0,1,350,464.45h0A114.45,114.45,0,0,1,235.68,350.14h0V235.82Z',
    ],
  },
  {
    symbol: 'w',
    basePath: [
      'M196.36,351.66a64.39,64.39,0,0,1,64.32-64.32h0A64.39,64.39,0,0,1,325,351.66h50.09a64.39,64.39,0,0,1,64.32-64.32h0a64.39,64.39,0,0,1,64.24,64.32h50A114.45,114.45,0,0,0,439.32,237.34h0A114.16,114.16,0,0,0,350,280.45a114.16,114.16,0,0,0-89.36-43.11h0A114.45,114.45,0,0,0,146.36,351.66Z',
    ],
    paths: [
      'M260.68,466h0A114.16,114.16,0,0,0,350,422.91a114.16,114.16,0,0,0,89.31,43v0A114.45,114.45,0,0,0,553.64,351.92h0V237.49h-50v114.3A64.39,64.39,0,0,1,439.32,416v0A64.39,64.39,0,0,1,375,351.62h0V237.36H325v114.3A64.39,64.39,0,0,1,260.68,416h0a64.39,64.39,0,0,1-64.32-64.32V237.36h-50V351.66h0A114.45,114.45,0,0,0,260.68,466Z',
      'M553.64,351.67c0,.08,0,.17,0,.25h0Z',
    ],
  },
  {
    symbol: 'x',
    paths: [
      'M349.83,350A114.45,114.45,0,0,0,464.15,464.32v-50A64.39,64.39,0,0,1,399.83,350h0a64.39,64.39,0,0,1,64.32-64.32v-50A114.45,114.45,0,0,0,349.83,350a114.45,114.45,0,0,0-114.32-114.3v50A64.39,64.39,0,0,1,299.83,350h0a64.39,64.39,0,0,1-64.32,64.32v50A114.45,114.45,0,0,0,349.83,350Z',
    ],
  },
  {
    symbol: 'y',
    paths: [
      'M464.3,352.25c0-.51,0-1,0-1.53h0v-115h-50v115.8A64.39,64.39,0,0,1,350,415h0a64.39,64.39,0,0,1-64.32-64.32v-115h-50v115A114.45,114.45,0,0,0,350,465h0a113.67,113.67,0,0,0,64.3-19.85V459.6h0A64.39,64.39,0,0,1,350,523.92v50A114.45,114.45,0,0,0,464.3,459.6h0Z',
    ],
  },
  {
    symbol: 'z',
    paths: [
      'M235.74,422.49l136.63-135.1H235.74V235.68H464.38v45.17L322,415.95h142.4v50H235.74Z',
    ],
  },
];
