import React from 'react';
import { Omit } from 'utility-types';
import { QuarterMode } from '../../page/prestation/prestationPagePros';

const QuarterSvg = ({
  mode,
  colors,
  ...props
}: {
  mode?: 'rainbow' | 'solid' | 'video' | 'photo' | 'matterport' | 'drone';
  colors?: [string, string];
} & { [key: string]: any }) => {
  let gradient;
  if (colors) {
    gradient = (
      <linearGradient id="rainbow" x1="-50%" y1="-50%" x2="150%" y2="150%">
        <stop
          offset={0}
          style={{
            stopColor: colors[0],
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: colors[1],
            stopOpacity: 1,
          }}
        />
      </linearGradient>
    );
  } else if (mode === 'rainbow') {
    gradient = (
      <linearGradient id="rainbow" x1="-50%" y1="-50%" x2="150%" y2="150%">
        <stop
          offset={0.1}
          style={{
            stopColor: '#FF3380',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.25}
          style={{
            stopColor: '#F054FF',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.5}
          style={{
            stopColor: '#5472FF',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={0.75}
          style={{
            stopColor: '#00F5B6',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#B6E439',
            stopOpacity: 1,
          }}
        />
      </linearGradient>
    );
  } else if (mode === 'matterport') {
    gradient = (
      <linearGradient id="rainbow" x1={0} y1={0} x2="100%" y2={0}>
        <stop
          offset={0}
          style={{
            stopColor: '#00f5b6',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#5472ff',
            stopOpacity: 1,
          }}
        />
      </linearGradient>
    );
  } else if (mode === 'photo') {
    gradient = (
      <linearGradient id="rainbow" x1={0} y1={0} x2="100%" y2={0}>
        <stop
          offset={0}
          style={{
            stopColor: '#F054FF',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#5472ff',
            stopOpacity: 1,
          }}
        />
      </linearGradient>
    );
  } else if (mode === 'video') {
    gradient = (
      <linearGradient id="rainbow" x1={0} y1={0} x2="100%" y2={0}>
        <stop
          offset={0}
          style={{
            stopColor: '#FF3380',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#5472ff',
            stopOpacity: 1,
          }}
        />
      </linearGradient>
    );
  } else if (mode === 'drone') {
    gradient = (
      <linearGradient id="rainbow" x1={0} y1={0} x2="100%" y2={0}>
        <stop
          offset={0}
          style={{
            stopColor: '#FFC75B',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#5472ff',
            stopOpacity: 1,
          }}
        />
      </linearGradient>
    );
  }

  return (
    <svg
      className="quarter"
      viewBox="0 0 387 387"
      height={387}
      width={387}
      {...props}
    >
      <defs>{gradient}</defs>
      <path
        fill={mode === 'solid' ? '#5472ff' : 'url(#rainbow)'}
        d="M387,0C173.3,0,0,173.6,0,387h171c0-118.9,97.1-215.7,216-216.2V0z"
      />
    </svg>
  );
};

export default QuarterSvg;
